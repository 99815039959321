import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import Axios from "axios";
import VueRouter from "vue-router";
import "./assets/tailwind.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.mixin(mixin);

Axios.defaults.baseURL = process.env.VUE_APP_URLAPI;
Axios.interceptors.request.use(
  (config) => {
    config.timeout = 10000;
    config.timeoutErrorMessage = "Time Out";
    const token = store.get("token");
    if (token) {
      if (!config.headers) {
        config.headers = [];
      }
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = `${token}`;
      }
      if (!config.headers["Content-type"]) {
        config.headers["Content-type"] = "application/json";
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject("request", error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    console.log("router.currentRoute.path", router.currentRoute.path);
    if (response.status == 200) {
      return Promise.resolve(response.data);
    } else {
      if (response.status === 401) {
        store.set("user", null);
        if (router.currentRoute.path !== "/login") {
          router.replace({ path: "/login" });
          return;
        }
      }
      Promise.reject(response);
    }
  },
  async (error) => {
    console.log("errorxxx", error);
    console.log("router.currentRoute.path", router.currentRoute.path);
    if (
      error.message.includes("status code 401") ||
      error.message.includes("UNAUTHORIZED")
    ) {
      store.set("user", null);
      if (!router.currentRoute.path === "/login") {
        router.replace({ path: "/login" });
        return;
      }
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
